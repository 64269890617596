import XEUtils from 'xe-utils'

// Math.toFixed，Math.round 有兼容问题，所以用库来处理四舍五入
export function roundNumber (num, len = 2) {
  return XEUtils.round(num, len)
}

// 格式化数字：1. 四舍五入；2. 千分位加符号分隔,若有小数位最多保留两位小数
export function formartNumber (num) {
  const floatNum = roundNumber(num)
  const numStrList = floatNum.toString().split('.')
  const result = numStrList.length > 1 ? numStrList[1].length : 0
  return XEUtils.commafy(floatNum, { digits: result })
}

/**
 * 注册resize事件
 */
export function bindResize (fn, delay, _this) {
  const debounced = XEUtils.debounce(fn, delay)
  window.addEventListener('resize', debounced) // 监听浏览器窗口变化重新加载
  _this.$once('hook:beforeDestroy', () => {
    window.removeEventListener('resize', debounced)
  })
}

/**
 * 将16进制的颜色转为rgba
 * @param {*} color
 * @param {*} opacity
 * @returns
 */
export function colorRgb (color, opacity) {
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  let color16 = color.toLowerCase()
  if (color16 && reg.test(color16)) {
    if (color16.length === 4) {
      let color16New = '#'
      for (let i = 1; i < 4; i += 1) {
        color16New += color16.slice(i, i + 1).concat(color16.slice(i, i + 1))
      }
      color16 = color16New
    }
    // 处理六位的颜色值
    const color16Change = []
    for (var i = 1; i < 7; i += 2) {
      color16Change.push(parseInt('0x' + color16.slice(i, i + 2)))
    }
    return 'rgba(' + color16Change.join(',') + ',' + opacity + ')'
  } else {
    return color16
  }
}

/**
 * 将普通列表转换为树结构的列表
 * @param {*} list
 */
export function listToTreeList (list) {
  if (!list || !list.length) {
    return []
  }
  const treeListMap = {}
  for (const item of list) {
    treeListMap[item.permId] = item
  }
  for (let i = 0; i < list.length; i++) {
    if (list[i].permPId && treeListMap[list[i].permPId]) {
      if (!treeListMap[list[i].permPId].children) {
        treeListMap[list[i].permPId].children = []
      }
      treeListMap[list[i].permPId].children.push(list[i])
      list.splice(i, 1)
      i--
    }
  }
  return list
}
/**
 * 下载文件
 * @param {*} filename
 */
export function saveAs (url, filename) {
  var saveLink = document.createElement('a')
  saveLink.href = url
  saveLink.download = filename
  const event = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window
  })
  saveLink.dispatchEvent(event)
  URL.revokeObjectURL(url)
}

/**
 * 导出文件
 * @param {*} filename
 */
export function exportToxlsx (data, fileName) {
  console.log('后端文件流', data)
  // const blob = new Blob([data], { type: 'application/x-excel' })
  if ('download' in document.createElement('a')) { // 非IE下载
    const link = document.createElement('a')
    link.download = fileName
    link.style.display = 'none'
    // console.log('blob', blob)
    link.href = URL.createObjectURL(data)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href) // 释放URL 对象
    document.body.removeChild(link)
  } else { // IE10+下载
    navigator.msSaveBlob(data, fileName)
  }
}

export function exportToxlsxGet (data, fileName) {
  const link = document.createElement('a')
  const blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
  link.style.display = 'none'
  link.href = URL.createObjectURL(blob)

  // link.download = res.headers['content-disposition'] //下载后文件名
  link.download = fileName // 下载的文件名
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// 返回圆外接矩形的最大最小经纬度
// 中国的经纬度：lon 经度 100多的，lat 维度 小的；
export function getCircleBround (lon, lat, raidus) {
  const PI = 3.14159265
  const latitude = lat
  const longitude = lon
  const degree = (24901 * 1609) / 360.0
  const raidusMile = raidus
  const dpmLat = 1 / degree
  const radiusLat = dpmLat * raidusMile
  const minLat = latitude - radiusLat
  const maxLat = latitude + radiusLat
  const mpdLng = degree * Math.cos(latitude * (PI / 180))
  const dpmLng = 1 / mpdLng
  const radiusLng = dpmLng * raidusMile
  const minLng = longitude - radiusLng
  const maxLng = longitude + radiusLng
  return minLng + ',' + minLat + ',' + maxLng + ',' + maxLat
}
